import React, {FC, memo, useState} from 'react';
import Authentication from '.';
import {UserContext} from '../contexts/user-context';
import {Children, HexofyUser, User} from '../types';

const Context: FC<Children> = ({children}) => {
  const [user, setUser] = useState<User | null>(null);
  const [hexofyUser, setHexofyUser] = useState<HexofyUser | null>(null);
  return (
    <UserContext.Provider value={{user, setUser, hexofyUser, setHexofyUser}}>
      <Authentication>{children}</Authentication>
    </UserContext.Provider>
  );
};

export default memo(Context);
