import Cookies from 'js-cookie';
import md5 from 'md5';
import moment from 'moment';
import {navigate} from 'gatsby';

import {CountryInfoType} from '../types';
import CountryCodes from '../constants/countries';

export const VALID_PASSWORD_RE =
  /^.*(?=.{8,})((?=.*[!@#$%^&*()\-_=+{};:,<.>]){1})(?=.*\d)((?=.*[a-z]){1})((?=.*[A-Z]){1}).*$/;

const CookieDomains = ['37.186.119.181', 'localhost', '.hexofy.com', '192.168.0.132'];

// TODO: REMOVE THIS!!!!!!!!
// TMP FIX FOR COOKIES
setTimeout(() => {
  // @ts-ignore
  if (
    global.window &&
    global.window.location &&
    global.window.location.hostname &&
    global.window.location.hostname.indexOf('hexofy.com') > -1
  ) {
    Cookies.remove('__session', {
      domain: undefined,
    });
  }
}, 100);

export const getRawCookie = (name: string): string | undefined => Cookies.get(name);
export const setRawCookie = (name: string, value: string) =>
  CookieDomains.map(domain => {
    Cookies.set(name, value),
      {
        expires: 30,
        domain: domain,
      };
  });

export const getCookies = (): any => {
  let cookiesStr: string | undefined = undefined;
  let cookies = {};
  for (let domain of CookieDomains) {
    //@ts-ignore
    cookiesStr = Cookies.get('__session', {domain: domain});
    if (cookiesStr) {
      try {
        cookies = JSON.parse(decodeURIComponent(cookiesStr));
      } catch (e) {}
    }
  }

  return cookies;
};

export const setCookie = (key: string, value: string | null | boolean, expires?: number) => {
  CookieDomains.map(domain => {
    Cookies.set('__session', JSON.stringify({...getCookies(), [key]: value}), {
      expires: expires ? expires : 30,
      domain: domain,
    });
  });
};

export const removeCookiePolicy = () => {
  CookieDomains.map(domain => {
    Cookies.remove('cookie_preference', {
      expires: 365,
      domain: domain,
    });
  });
};

export const setCookiePolicy = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('cookie_preference', value, {
      expires: 365,
      domain: domain,
    });
  });
};

export const setImpactClickIdCookie = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('__irClickId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setImpactMediaPartnerIdCookie = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('__mediaPartnerId', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactClickIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__irClickId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeImpactMediaPartnerIdCookie = () => {
  CookieDomains.map(domain => {
    Cookies.remove('__mediaPartnerId', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setDocumentReferrer = (value: string) => {
  CookieDomains.map(domain => {
    Cookies.set('document_referrer', value, {
      expires: 30,
      domain: domain,
    });
  });
};

export const removeDocumentReferrer = () => {
  CookieDomains.map(domain => {
    Cookies.remove('document_referrer', {
      expires: 30,
      domain: domain,
    });
  });
};

export const getCookie = (key: string): string => getCookies()[key];
export const removeCookie = (key: string) => {
  const cookies = getCookies();
  delete cookies[key];
  CookieDomains.map(domain => {
    Cookies.remove('__session', {
      expires: 30,
      domain: domain,
    });
  });
};

export const setUserToken = (token: string) => setCookie('user-token', token);
export const setUserHash = (hash: string) => setCookie('user-hash', hash);
export const getUserToken = (): string | undefined => getCookie('user-token');
export const getUserHash = (): string | undefined => getCookie('user-hash');
export const removeUserToken = () => setCookie('user-token', null);
export const removeUserHash = () => setCookie('user-hash', null);

export const setUserEmail = (email: string) => setCookie('user-email', email);
export const getUserEmail = (): string | undefined => getCookie('user-email');
export const removeUserEmail = () => setCookie('user-email', null);

export const setUserName = (name: string) => setCookie('user-name', name);
export const getUserName = (): string | undefined => getCookie('user-name');
export const removeUserName = () => setCookie('user-name', null);

export const setNewUser = (isNew: boolean) => setCookie('new-user', isNew);
export const getNewUser = (): string | undefined => getCookie('new-user');
export const removeNewUser = () => removeCookie('new-user');

export const getRefreshHash = (): string | undefined => getCookie('user-hash');

export const setDeviceKey = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('device_key', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

export const setRefreshToken = (hash: string) => {
  CookieDomains.map(domain => {
    Cookies.set('ref_token', hash, {
      expires: 30,
      domain: domain,
    });
  });
};

// export const getGravatar = (email: string, size?: number) => {
//   size = size || 80;
//   return 'https://www.gravatar.com/avatar/' + md5(email) + '?s=' + size + '&d=monsterid';
// };

export const logOut = () => {
  removeUserToken();
  removeUserHash();
  removeUserEmail();
  removeUserName();
  if (!getUserName()) {
    navigate('/');
  }
};

export const getDateAndTime = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY h:mm:ss A (UTC)');
};

export const getParsedDateAndTimeGMT = (date: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate ? moment(parsedDate).format('MMM DD, YYYY HH:mm:ss') : '';
};

export const getPackageInfo = () => {
  return !!localStorage.getItem('packageInfo');
};

export const removePackageInfo = () => {
  localStorage.removeItem('packageInfo');
};

export const isValidEmail = (mail: string): boolean =>
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    mail,
  );

export const eventStop = (e: any) => {
  if (e) {
    e.preventDefault();
    e.stopPropagation();
  }
};

export const returnDashUrl = () => {
  return window.location.host.includes('localhost') ? 'http://localhost:8000' : 'https://hexofy.com/';
};

export const returnDashUrlWithPath = (path: any) => {
  return window.location.host.includes('localhost') ? `http://localhost:8000${path}` : `https://hexofy.com${path}`;
};

export const sendUserDataToExtension = (token?: any, refToken?: string, deviceKey?: string) => {
  // The ID of the extension we want to talk to.
  //! store id => not set yet
  const editorExtensionIdArr = [
    'bmcfkceaciejniimbpekgcgkodlgfadj',
    'nenkkhfkbgioenfhecolhdjjpbgbmbmn',
    'cabkcpckbogodkhpkflapdjhlaoojpal',
    'cfmhjjglgpcbkbpmicpkegadmoeicedc',
    'nenimlehhngpokejigjcfdjobmkfphnn',
    'dlbbedjpdncbbicnpimoghakfiakhddb',
    'fjgeobgcbipefmbggcbkdihjlabjbgba',
    'mnhjjidfkpmkhdpdlpmacjonamgamdpm',
    'pndfblhdnbhojknmcfjcfigjkejepfag',
    'jmpflegmbfmkimhdhfbjhpnncahdmodo',
    'mgmcleaniklmnobmapoghodebomcclld',
    'nebdjjiofgkbbiohgkcdmdjjeobaipdg',
    'ckgmhanamjlhmfhlcibaljcacobffdjh',
    'ieegoibolaojfjcncgeijaafbfpppfkg',
    'hpkeojndcogejcnjkdnjgbcbmmoplkdk',
  ];
  //@ts-ignore
  global.window &&
    //@ts-ignore
    global.window.chrome &&
    //@ts-ignore
    global.window.chrome.runtime &&
    editorExtensionIdArr.forEach(async (item: string) => {
      try {
        //@ts-ignore
        await global.window.chrome.runtime.sendMessage(item, {token: token || null, refToken, deviceKey});
      } catch {
        return;
      }
    });

  (() => {
    document.dispatchEvent(
      new CustomEvent('extension-hexofy-token-event', {
        detail: {token: token || null, refToken, deviceKey},
      }),
    );
  })();
};

export const closePopupOnEsc = (handlePopupStateChange: any) => {
  const handleEsc = (e: any) => {
    if (e.key === 'Escape') {
      e.preventDefault();
      e.stopPropagation();

      const elements = document.querySelectorAll(`[id^="popup-"]`);
      const elementsModal = document.querySelectorAll('.customModal');

      if (elements.length && elementsModal.length) {
        const bodyElement = document.getElementsByTagName('body')[0];
        const childEl = elements[elements.length - 1];
        bodyElement.removeChild(childEl);
      } else {
        handlePopupStateChange && handlePopupStateChange();
      }
    }
  };
  const addEvent = () => window.addEventListener('keydown', handleEsc);
  const removeEvent = () => window.removeEventListener('keydown', handleEsc);

  return [addEvent, removeEvent];
};

export const getDate = (date: string | number) => {
  return date && moment.utc(new Date(date).toISOString()).format('MMM DD, YYYY');
};

export const GetCountry = (countryCode: string): CountryInfoType => CountryCodes[countryCode];

export const getParsedDateAndTime = (date: string, dateFormat: string, timeFormat: string) => {
  const parsedDate = parseInt(date) && !isNaN(parseInt(date)) && new Date(parseInt(date));
  return parsedDate
    ? moment
        .utc(parsedDate.toISOString())
        .format(`${dateFormat} ${timeFormat === '12 hour' ? 'h:mm:ss A' : 'HH:mm:ss'}`)
    : '';
};

export const setBase32 = (base32: string) => {
  CookieDomains.map(domain => {
    Cookies.set('base32', base32, {
      expires: 30,
      domain,
    });
  });
};

export const getHexowatchPricingPackageName = (value: string, additionalScansCount?: number) => {
  if (value && value.indexOf('FREE') > -1) {
    return `Free ${
      additionalScansCount && additionalScansCount > 1000
        ? (additionalScansCount / 1000).toFixed(1)
        : additionalScansCount
        ? additionalScansCount
        : ''
    }${additionalScansCount && additionalScansCount > 1000 ? 'k' : ''} `;
  }
  if (value && value.indexOf('STANDARD') > -1) {
    return `Standard ${
      additionalScansCount
        ? ((additionalScansCount + 2000) / 1000).toFixed((additionalScansCount + 2000) % 1000 > 0 ? 1 : 0)
        : 2
    }k`;
  }
  if (value && value.indexOf('PRO') > -1) {
    return `Pro ${
      additionalScansCount
        ? ((additionalScansCount + 4500) / 1000).toFixed((additionalScansCount + 4500) % 1000 > 0 ? 1 : 0)
        : 4.5
    }k`;
  }
  if (value && value.indexOf('BUSINESS_SCANS_') > -1) {
    const limit = value.slice(value.lastIndexOf('_') + 1);
    const limitIn1000 = +limit;
    if (value !== 'BUSINESS_SCANS_10000') {
      return `Business+ ${
        additionalScansCount
          ? ((additionalScansCount + limitIn1000) / 1000).toFixed(
              (additionalScansCount + limitIn1000) % 1000 > 0 ? 1 : 0,
            )
          : limitIn1000 / 1000
      }k`;
    } else {
      return `Business ${
        additionalScansCount
          ? ((additionalScansCount + 10000) / 1000).toFixed((additionalScansCount + 10000) % 1000 > 0 ? 1 : 0)
          : 10
      }k`;
    }
  }
};

export const getPromoDate = () => {
  // Get current date and time in GMT
  const currentDate = new Date();
  const currentDay = currentDate.getUTCDay(); // Sunday is 0, Monday is 1, ..., Saturday is 6
  const currentHour = currentDate.getUTCHours();

  // Calculate time remaining until the next Friday 11 AM GMT
  let daysUntilFriday = (5 - currentDay + 7) % 7; // 5 is the day index for Friday
  if (currentDay === 5 && currentHour >= 7) {
    // If it's already Friday after 11 AM, consider the next Friday
    daysUntilFriday += 7;
  }

  // Calculate the next Friday's date
  const nextFriday = new Date(currentDate);
  nextFriday.setUTCDate(currentDate.getUTCDate() + daysUntilFriday);
  nextFriday.setUTCHours(7, 0, 0, 0); // Set time to 11 AM GMT

  return nextFriday.toUTCString();
};