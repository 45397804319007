import React from 'react';
import HelmetTitle from './src/components/helmet';
import {ApolloWrapper} from './src/graphql';
import AuthenticationContext from './src/authentication/context';

import 'bootstrap/dist/css/bootstrap.min.css';
import './src/styles/styles.scss';

export const wrapRootElement = ({element}) => {
  return (
    <ApolloWrapper>
      <AuthenticationContext>{element}</AuthenticationContext>
    </ApolloWrapper>
  );
};

export const wrapPageElement = ({element}) => {
  return (
    <>
      <HelmetTitle />
      {element}
    </>
  );
};
