import React, {useContext, useEffect, useState} from 'react';
import {navigate} from 'gatsby';
import {useLazyQuery} from '@apollo/client';

import {ME, HEXOFY_USER} from '../graphql/queries';
import {UserContext} from '../contexts/user-context';

const useSetUser = (only?: 'user' | 'hexofy_user') => {
  const {setUser, setHexofyUser} = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [refetch, {data: userData, loading: userDataLoading}] = useLazyQuery(ME, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });
  const [refetchUser, {data: hexofyUserData, loading: hexofyUserLoading}] = useLazyQuery(HEXOFY_USER, {
    fetchPolicy: 'no-cache',
    context: {clientName: 'v2'},
  });

  useEffect(() => {
    if (only !== 'hexofy_user' && userData && userData.User && userData.User.get) {
      setUser(userData.User.get.id ? {...userData.User.get} : {});
    }
  }, [userData]);

  useEffect(() => {
    if (only !== 'user' && hexofyUserData?.HexofyUser?.getHexofyUser) {
      setHexofyUser(
        hexofyUserData.HexofyUser.getHexofyUser?.hfUser?.id ? {...hexofyUserData.HexofyUser.getHexofyUser} : {},
      );
    }
  }, [hexofyUserData]);

  useEffect(() => {
    setLoading(hexofyUserLoading || userDataLoading);
  }, [hexofyUserLoading, userDataLoading]);

  const getUser = async (shouldNavigate?: boolean, setEmptyUser?: boolean) => {
    only !== 'hexofy_user' && await refetch({
      variables: {
        toggle: new Date().toString(),
      }
    });
    only !== 'user' && await refetchUser({
      variables: {
        toggle: new Date().toString(),
      }
    });
    shouldNavigate && navigate('/');
    setEmptyUser && !userData?.User?.get?.id && setUser(userData?.User?.get?.id ? {...userData.User.get} : {});
  };
  return {getUser, loading: loading};
};

export default useSetUser;
