exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-contact-index-tsx": () => import("./../../../src/pages/contact/index.tsx" /* webpackChunkName: "component---src-pages-contact-index-tsx" */),
  "component---src-pages-cookie-policy-index-tsx": () => import("./../../../src/pages/cookie-policy/index.tsx" /* webpackChunkName: "component---src-pages-cookie-policy-index-tsx" */),
  "component---src-pages-faq-index-tsx": () => import("./../../../src/pages/faq/index.tsx" /* webpackChunkName: "component---src-pages-faq-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacy-policy-index-tsx": () => import("./../../../src/pages/privacy-policy/index.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-index-tsx" */),
  "component---src-pages-profile-index-tsx": () => import("./../../../src/pages/profile/index.tsx" /* webpackChunkName: "component---src-pages-profile-index-tsx" */),
  "component---src-pages-profile-my-plans-index-tsx": () => import("./../../../src/pages/profile/my-plans/index.tsx" /* webpackChunkName: "component---src-pages-profile-my-plans-index-tsx" */),
  "component---src-pages-profile-payment-methods-index-tsx": () => import("./../../../src/pages/profile/payment-methods/index.tsx" /* webpackChunkName: "component---src-pages-profile-payment-methods-index-tsx" */),
  "component---src-pages-profile-promo-codes-index-tsx": () => import("./../../../src/pages/profile/promo-codes/index.tsx" /* webpackChunkName: "component---src-pages-profile-promo-codes-index-tsx" */),
  "component---src-pages-profile-security-devices-tsx": () => import("./../../../src/pages/profile/security/devices.tsx" /* webpackChunkName: "component---src-pages-profile-security-devices-tsx" */),
  "component---src-pages-profile-security-index-tsx": () => import("./../../../src/pages/profile/security/index.tsx" /* webpackChunkName: "component---src-pages-profile-security-index-tsx" */),
  "component---src-pages-profile-security-two-factor-tsx": () => import("./../../../src/pages/profile/security/two-factor.tsx" /* webpackChunkName: "component---src-pages-profile-security-two-factor-tsx" */),
  "component---src-pages-promo-95093-index-tsx": () => import("./../../../src/pages/promo95093/index.tsx" /* webpackChunkName: "component---src-pages-promo-95093-index-tsx" */),
  "component---src-pages-promo-95702-index-tsx": () => import("./../../../src/pages/promo95702/index.tsx" /* webpackChunkName: "component---src-pages-promo-95702-index-tsx" */),
  "component---src-pages-reset-password-index-tsx": () => import("./../../../src/pages/reset-password/index.tsx" /* webpackChunkName: "component---src-pages-reset-password-index-tsx" */),
  "component---src-pages-response-msg-index-tsx": () => import("./../../../src/pages/response-msg/index.tsx" /* webpackChunkName: "component---src-pages-response-msg-index-tsx" */),
  "component---src-pages-team-access-index-tsx": () => import("./../../../src/pages/team-access/index.tsx" /* webpackChunkName: "component---src-pages-team-access-index-tsx" */),
  "component---src-pages-terms-of-use-index-tsx": () => import("./../../../src/pages/terms-of-use/index.tsx" /* webpackChunkName: "component---src-pages-terms-of-use-index-tsx" */),
  "component---src-pages-unsubscribe-index-tsx": () => import("./../../../src/pages/unsubscribe/index.tsx" /* webpackChunkName: "component---src-pages-unsubscribe-index-tsx" */),
  "component---src-pages-verify-email-index-tsx": () => import("./../../../src/pages/verify-email/index.tsx" /* webpackChunkName: "component---src-pages-verify-email-index-tsx" */),
  "component---src-pages-zg-fza-c-1-y-zw-rpcm-vjd-a-index-tsx": () => import("./../../../src/pages/ZGFzaC1yZWRpcmVjdA==/index.tsx" /* webpackChunkName: "component---src-pages-zg-fza-c-1-y-zw-rpcm-vjd-a-index-tsx" */)
}

